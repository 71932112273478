import type { DialogNames } from 'store/types'
import type { SessionDialog, SessionDialogsFlags } from './types'

export const checkRouteList = (ignoreRoutes: string[], currentRoute: string): boolean => {
  return ignoreRoutes?.some((route) => currentRoute.includes(route))
}

export const getNextSessionDialog = (
  dialogs: SessionDialog[],
  {
    currency,
    platform,
    flags,
  }: {
    currency: 'GC' | 'SC'
    platform: 'WEB'
    flags: SessionDialogsFlags
  },
  historySessionDialogs: DialogNames[],
  currentRoute: string
): SessionDialog | null => {
  // Find dialog for current platform and currency and flag
  // eslint-disable-next-line no-restricted-syntax
  for (const dialog of dialogs) {
    const { modalName, platforms, mode, flag, ignoreRoutes, allowRoutes } = dialog
    const isModeCurrency = mode.includes(currency)
    const isPlatform = platforms.includes(platform)
    const isFlag = flags[flag as keyof typeof flags]
    const isIgnoreRoute = checkRouteList(ignoreRoutes, currentRoute)
    const dialogWasOpened = historySessionDialogs.includes(modalName)
    const isAllowRoutes = allowRoutes ? checkRouteList(allowRoutes, currentRoute) : true

    if (!dialogWasOpened && isModeCurrency && isPlatform && isFlag && !isIgnoreRoute && isAllowRoutes) {
      return dialog
    }
  }

  return null
}
