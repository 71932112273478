import isWebView from 'is-ua-webview'
import { useEffect } from 'react'
import 'next-pwa/register'

const registerWorkbox = (): Promise<any> => {
  try {
    // @ts-ignore
    return window.workbox.register()
  } catch (error) {
    return Promise.reject(error)
  }
}

const registerWorkboxWithLog = (isRetry = false) => {
  return registerWorkbox().catch((error) => {
    console.log(`Service Worker ${isRetry ? 'retry ' : ''}registration failed: `, error)
    throw error
  })
}

export default function useRegisterWorkbox() {
  useEffect(() => {
    // register service worker only on desktop and supported browsers
    if (!isWebView(window.navigator.userAgent) && navigator?.serviceWorker) {
      // retry registration with timeout
      let retryTimerId: ReturnType<typeof setTimeout> = null
      registerWorkboxWithLog().catch(() => {
        retryTimerId = setTimeout(registerWorkboxWithLog, 3000, true)
      })

      return () => clearTimeout(retryTimerId)
    }
  }, [])
}
