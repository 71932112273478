'use client'

import ReactDOM from 'react-dom'

const GOOGLE_STORAGE_DOMAIN = 'https://storage.googleapis.com'

export function PreloadResources(): null {
  // @ts-ignore
  ReactDOM.preconnect(GOOGLE_STORAGE_DOMAIN)

  return null
}
