import { createSelector } from '@reduxjs/toolkit'
import type { ReduxState } from 'src/store/types'

const getDailyBonusesRequest = (state: ReduxState) => state.sessionDialogs.dailyBonuses
const getLegalUpdateRequest = (state: ReduxState) => state.sessionDialogs.legalUpdate
const getOffersRequest = (state: ReduxState) => state.sessionDialogs.offers
const getFreeSpinsRequest = (state: ReduxState) => state.sessionDialogs.freeSpins
const getHistorySessionDialogs = (state: ReduxState) => state.sessionDialogs.historySessionDialogs

export const getDailyBonusesRequestSelector = createSelector(getDailyBonusesRequest, (dailyBonuses) => dailyBonuses)
export const getLegalUpdateRequestSelector = createSelector(getLegalUpdateRequest, (legalUpdate) => legalUpdate)
export const getOffersRequestSelector = createSelector(getOffersRequest, (isOffersRequest) => isOffersRequest)
export const getFreeSpinsRequestSelector = createSelector(getFreeSpinsRequest, (freeSpins) => freeSpins)
export const getHistorySessionDialogsSelector = createSelector(
  getHistorySessionDialogs,
  (dialogsWasOpened) => dialogsWasOpened
)

export const receivedAllRequestsSelector = createSelector(
  getDailyBonusesRequestSelector,
  getLegalUpdateRequestSelector,
  getOffersRequestSelector,
  getFreeSpinsRequestSelector,
  (dailyBonuses, legalUpdate, isOffersRequest, freeSpins) =>
    !dailyBonuses || !legalUpdate || !isOffersRequest || !freeSpins
)
