import { useEffect } from 'react'
// @ts-ignore
import { usePageVisibility } from 'react-page-visibility'
import { isBrowser } from '@patrianna/shared-utils/helpers'
import { useAppDispatch } from 'src/store/hooks'
import { appStateActiveHandler, appStateInactiveHandler } from 'services/websocket'

export default function VisibilityProvider(): null {
  const isVisible = usePageVisibility()
  const dispatch = useAppDispatch()

  useEffect(() => {
    // tab becomes inactive
    if (!isVisible) {
      dispatch(appStateInactiveHandler())
    }

    // tab becomes active
    if (isBrowser() && isVisible) {
      dispatch(appStateActiveHandler())
      // tab in background
    }
  }, [isVisible])

  return null
}
