import type { AnyAction } from '@reduxjs/toolkit'
import {
  verificationsKYCInfoResponseActionName,
  verificationsKYCInfoResponseErrorActionName,
  verificationsSetIsRedeemingActionName,
  verificationsSetKycStatusActionName,
} from '@patrianna-payments/shared-store/verifications/store/actions'
import { verificationsSlice } from '@patrianna-payments/shared-store/verifications/store/slice'
import { setIsRedeeming } from '@patrianna-payments/shared-store/redeem'
import type { TypedThunk } from 'store/types'
import { openDialog, removeDialogByName } from 'store/modules/dialog/actions'
import { openSnackbar } from 'store/modules/snackbar/actions'
import { setKycStatus } from 'store/modules/user/actions'
import type { MiddlewareType } from './types'
import type { KYCVerificationIframeParams } from '@patrianna-payments/shared-store/verifications/hooks/docs-verification/useKYCVerificationIframe'

const handleKYCInfoResponseAction =
  (response: KYCVerificationIframeParams): TypedThunk =>
  (dispatch) => {
    dispatch(removeDialogByName({ modalName: 'WITHDRAW_DIALOG' }))
    dispatch(removeDialogByName({ modalName: 'SUBMIT_DOCUMENTS_DIALOG' }))
    dispatch(removeDialogByName({ modalName: 'GET_VERIFIED_DIALOG' }))
    dispatch(openDialog({ modalName: 'VERIFICATION_KYC_DIALOG', dialogProps: response }))
    dispatch(setIsRedeeming({ isRedeeming: false }))
  }

export const verificationsMiddleware: MiddlewareType =
  ({ dispatch }) =>
  (next) =>
  (action: AnyAction) => {
    if (action?.type?.includes(verificationsSlice.name)) {
      if (action.type === verificationsKYCInfoResponseActionName) {
        dispatch(handleKYCInfoResponseAction(action.payload))
      }

      if (action.type === verificationsSetIsRedeemingActionName) {
        dispatch(setIsRedeeming(action.payload))
      }

      if (action.type === verificationsSetKycStatusActionName) {
        dispatch(setKycStatus(action.payload))
      }

      if (action.type === verificationsKYCInfoResponseErrorActionName) {
        dispatch(openSnackbar({ message: action.payload.error }))
      }
    }

    next(action)
  }
