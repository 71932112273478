import { checkInviteFriendsModalVisibility, checkLegalsModalVisibility } from 'src/store/modules/user/actions'
import {
  checkInvitationUserBonuses,
  checkManualUserBonuses,
  checkWeeklyRewardBonuses,
} from 'store/modules/bonusesFlow/actions'

// import { openWelcomeEmailDialogWithDelay } from 'store/modules/user/actions'

export const actionsMap = {
  checkManualUserBonuses,
  checkInvitationUserBonuses,
  checkLegalsModalVisibility,
  checkWeeklyRewardBonuses,
  checkInviteFriendsModalVisibility,
  // openWelcomeEmailDialogWithDelay
}
