'use client'

import { detectMobile } from '@patrianna/shared-utils'
import type { ReactNode } from 'react'
import { createContext, memo, useContext, useEffect, useState } from 'react'

const DetectMobileContext = createContext<boolean>(null)

interface DetectMobileProps {
  children: ReactNode
  isMobile: boolean
}

function DetectMobileProvider(props: DetectMobileProps) {
  const { children, isMobile } = props
  const [mobile, setMobile] = useState(isMobile)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setMobile(detectMobile())
    }
  }, [])

  return <DetectMobileContext.Provider value={mobile}>{children}</DetectMobileContext.Provider>
}

const DetectMobileProviderMemo = memo(DetectMobileProvider)

function useIsMobile() {
  const mobile = useContext(DetectMobileContext)

  if (mobile === undefined) {
    throw new Error('useIsMobile must be used within a DetectMobileProvider')
  }

  return mobile
}

export { DetectMobileProviderMemo as DetectMobileProvider, useIsMobile }
