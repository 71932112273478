'use client'

import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { NavigationProvider } from 'app/context/navigation'
import store from 'src/store'
import { TranslationsProvider } from 'app/context/translations'
import { AuthProvider } from 'app/context/auth'
import type { AuthCookies } from 'app/utils/getAuthCookies'
import { DetectMobileProvider } from 'app/context/detectMobile'
import { CountryConfigProvider } from 'app/context/country'

type Props = {
  children: ReactNode
  translations: any
  authCookies: AuthCookies
  isMobile: boolean
  countryCode: string
}

export function Providers({ children, translations, authCookies, isMobile, countryCode }: Props) {
  useEffect(() => {
    // TODO: temporary solution for handling translation keys within validator utility. Need to rework this.
    // @ts-ignore
    window.__FORM_VALIDATION_TRANSLATIONS__ = {
      formValidators: translations?.formValidators,
      verification: translations?.pageIdentityVerification,
    }
  }, [translations])

  return (
    <DetectMobileProvider isMobile={isMobile}>
      <Provider store={store}>
        <CountryConfigProvider countryCode={countryCode}>
          <TranslationsProvider translations={translations}>
            <AuthProvider authCookies={authCookies}>
              <NavigationProvider>{children}</NavigationProvider>
            </AuthProvider>
          </TranslationsProvider>
        </CountryConfigProvider>
      </Provider>
    </DetectMobileProvider>
  )
}
