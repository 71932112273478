import { requestErrorsGAHandler } from '@patrianna-payments/analytics/gtm/events'
import type { TypedDispatch } from 'src/store/types'
import { openSnackbar } from 'store/modules/snackbar/actions'

export type Error = {
  statusText: string
  errorText?: string
  errorCode?: 'err_denied'
  message?: string
}

const ERROR_CODES_TO_SKIP = ['err_duplicate', 'err_password_incorrect', 'err_frozen']

export const openErrorSnackbar = (error: Error) => (dispatch: TypedDispatch) => {
  const errMessage = error.errorText || error.message || error.statusText
  const errCode = error?.errorCode

  // check err_duplicate
  if (!(errCode && ERROR_CODES_TO_SKIP.includes(errCode))) {
    if (errMessage && errCode) {
      dispatch(openSnackbar({ message: errMessage }))
    }
  }

  requestErrorsGAHandler(errCode)
}
