import type { GetAccountInfoRequest } from '@patrianna/shared-patrianna-types/websocket/requests'
import type { GetAccountInfoResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import type { ServiceDependenciesOptions } from 'src/services'

export const getAccountInfo = (gateway: ServiceDependenciesOptions['gateway']): Promise<GetAccountInfoResponse> => {
  const data: GetAccountInfoRequest = {
    type: 'GetAccountInfoRequest',
  }

  return gateway.emit<GetAccountInfoResponse>(data)
}

export const isGmail = (email: string | undefined) => {
  if (!email) {
    return false
  }

  return email.includes('@gmail') || email.includes('@google') || email.includes('@patrianna')
}
