import type { AnyAction } from '@reduxjs/toolkit'
import {
  redeemAvailablePaymentProvidersRequestActionName,
  redeemCloseAllDialogsActionName,
  redeemCloseLatestDialogActionName,
  redeemOpenDialogActionName,
  redeemOpenSnackbarActionName,
  redeemReplaceDialogActionName,
  redeemSetDataToLocalStorageActionName,
  redeemTrackGAEventActionName,
} from '@patrianna-payments/shared-store/redeem'
import type { ActionTypeMapFnRedeem } from '@patrianna-payments/shared-store/redeem'
import type { MiddlewareType } from 'store/middlewares/types'
import { getAvailablePaymentProvidersRequest } from 'store/modules/appConfig/actions'
import { openSnackbar } from 'store/modules/snackbar/actions'
import { setDataToLocalStorage } from 'utils/localStorage'
import { closeAllDialogs, closeLatestDialog, openDialog, replaceDialog } from '../modules/dialog/actions'
import { redeemTrackGAEvent } from 'store/modules/redeem/actions'

const actionsMap: Record<string, ActionTypeMapFnRedeem> = {
  [redeemSetDataToLocalStorageActionName]: setDataToLocalStorage,
  [redeemReplaceDialogActionName]: replaceDialog,
  [redeemOpenSnackbarActionName]: openSnackbar,
  [redeemOpenDialogActionName]: openDialog,
  [redeemCloseLatestDialogActionName]: closeLatestDialog,
  [redeemCloseAllDialogsActionName]: closeAllDialogs,
  [redeemAvailablePaymentProvidersRequestActionName]: getAvailablePaymentProvidersRequest,
  [redeemTrackGAEventActionName]: redeemTrackGAEvent,
}

export const redeemMiddleware: MiddlewareType =
  ({ dispatch }) =>
  (next) =>
  (action: AnyAction) => {
    if (action.type in actionsMap) {
      dispatch(actionsMap[action.type](action?.payload))
    }

    next(action)
  }
