import type { TypedThunk } from 'src/store/types'
import { getPragmaticJackpots } from 'src/utils/loadGameInfoGetInitialProps'
import { actions } from './slice'

export const { savePragmaticJackpots } = actions

export const getPragmaticJackpotsAction =
  (currency: 'SC' | 'GC'): TypedThunk =>
  async (dispatch) => {
    await getPragmaticJackpots(currency).then((jackpots) => {
      dispatch(savePragmaticJackpots({ jackpots }))
    })
  }
