import { usePathname } from 'next/navigation'
import { useRouter } from 'app/context/navigation'
import useRequireKYCVerificationRedirect from '@patrianna-payments/shared-store/verifications/hooks/docs-verification/useRequireKYCVerificationRedirect'
import { useAppSelector } from 'store/hooks'
import {
  isKYCConfirmedSelector,
  needToVerifyJumioKYCSelector,
  needToVerifyEmailSelector,
} from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'

export default function useRequireKYCIdentityVerification() {
  const router = useRouter()
  const pathname = usePathname()

  const isConfirmed = useAppSelector(isKYCConfirmedSelector)
  const needToVerifyJumioKYC = useAppSelector(needToVerifyJumioKYCSelector)
  const needToVerifyEmail = useAppSelector(needToVerifyEmailSelector)

  const redirectToIdentityVerification = () => {
    router.push(ROUTES.IDENTITY_VERIFICATION)
  }

  useRequireKYCVerificationRedirect({
    isConfirmed,
    needToVerify: needToVerifyJumioKYC && !needToVerifyEmail,
    isAllowedPage: [ROUTES.IDENTITY_VERIFICATION, ROUTES.OTP].includes(pathname),
    redirectToIdentityVerification,
  })
}
