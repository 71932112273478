import type { AnyAction } from '@reduxjs/toolkit'
import type {
  AuthMethod,
  AuthViaSocialAPI,
  GatewayRegisterResponse,
  RegisterRequest,
  SocialAuthTypes,
} from '@patrianna/shared-patrianna-types/store/AuthModule'
import type { ActionTypeMapFnAuth } from '@patrianna/shared-store/auth'
import {
  authErrorHandleActionName,
  authLogOutSuccessActionName,
  authLoginSuccessHandleActionName,
  authOpenDialogActionName,
  authOpenErrorSnackbarActionName,
  authOpenSnackbarActionName,
  authRemoveAuthTypeFromLocalStorageActionName,
  authSetAuthTypeFromLocalStorageActionName,
  authSetOpenPrerequisitesActionName,
  authShowAuthConfirmDialogActionName,
  authSocialSignUpSuccessActionName,
  authSuccessHandleActionName,
  authTrackLoginActionName,
  authViaEmailSuccessActionName,
} from '@patrianna/shared-store/auth/store/middleware-actions'
import type { ShowAuthConfirmDialog } from '@patrianna/shared-store/auth/store/types'
import { trackLogin, trackSignUp } from 'src/analyticActions/common'
import {
  LS_AUTH_TYPE,
  removeDataFromLocalStorage,
  setDataToLocalStorage,
  IS_SIGN_UP_SESSION,
} from 'src/utils/localStorage'
import { openErrorSnackbar } from 'src/utils/request'
import type { TypedThunk } from 'store/types'
import { authErrorHandler, authSuccessHandle, logOutSuccess } from 'store/modules/auth/actions'
import { setOpenPrerequisites } from 'store/modules/prerequisites/actions'
import { openSnackbar } from 'store/modules/snackbar/actions'
import { closeAllDialogs, openDialog, removeDialogByName } from '../modules/dialog/actions'
import { userInitilization } from '../modules/userInitilization/actions'
import type { MiddlewareType } from './types'
import type { useSearchParams } from 'next/navigation'

const authSuccessHandler =
  ({
    routerPush,
    routerQuery,
    routerPath,
    cb,
  }: {
    routerPush: (path: string, callback?: () => void) => void
    routerQuery: ReturnType<typeof useSearchParams>
    routerPath: string
    cb?: ({ country, userId, state }: { country: string; userId: string; state?: string }) => void
  }): TypedThunk =>
  (dispatch) => {
    dispatch(userInitilization(routerPush, routerQuery, routerPath, cb))
    dispatch(authSuccessHandle())
  }

const authSuccessHandleAction =
  ({
    routerPush,
    routerQuery,
    routerPath,
  }: {
    routerPush: (path: string, callback?: () => void) => void
    routerQuery: ReturnType<typeof useSearchParams>
    routerPath: string
  }): TypedThunk =>
  (dispatch) => {
    dispatch(userInitilization(routerPush, routerQuery, routerPath))
    dispatch(authSuccessHandle({ doNotCloseSnackbar: true }))
    dispatch(closeAllDialogs())
  }

const authViaEmailSuccessHandleAction =
  ({
    routerPush,
    routerQuery,
    routerPath,
  }: {
    data: GatewayRegisterResponse
    request: any
    birthDate: string
    first_utm: string
    query: string
    routerPush: (path: string, callback?: () => void) => void
    routerQuery: ReturnType<typeof useSearchParams>
    routerPath: string
  }): TypedThunk =>
  (dispatch) => {
    dispatch(setDataToLocalStorage(IS_SIGN_UP_SESSION, true, false))
    dispatch(
      authSuccessHandler({
        routerPush,
        routerQuery,
        routerPath,
        cb: ({ country, userId, state }) => {
          trackSignUp({ userId, method: 'Email', country, state })
        },
      })
    )
  }

const authRemoveAuthTypeFromLocalStorage = (): TypedThunk => (dispatch) => {
  dispatch(removeDataFromLocalStorage(LS_AUTH_TYPE, false))
}

const authSetAuthTypeFromLocalStorage =
  ({ authType }: { authType: SocialAuthTypes }): TypedThunk =>
  (dispatch) => {
    dispatch(setDataToLocalStorage(LS_AUTH_TYPE, authType, false))
  }

const authSocialSignUpSuccess =
  ({
    method,
    routerPush,
    routerQuery,
    routerPath,
  }: {
    form: AuthViaSocialAPI
    method: AuthMethod
    first_utm: string
    query: string
    request: RegisterRequest
    routerPush: (path: string, callback?: () => void) => void
    routerQuery: ReturnType<typeof useSearchParams>
    routerPath: string
  }): TypedThunk =>
  (dispatch) => {
    dispatch(setDataToLocalStorage(IS_SIGN_UP_SESSION, true, false))
    dispatch(
      authSuccessHandler({
        routerPush,
        routerQuery,
        routerPath,
        cb: ({ country, userId, state }) => {
          trackSignUp({ userId, method, country, state })
        },
      })
    )
    dispatch(removeDataFromLocalStorage(LS_AUTH_TYPE, false))
  }

const authLoginSuccessHandle =
  ({
    routerPush,
    routerQuery,
    routerPath,
  }: {
    routerPush: (path: string, callback?: () => void) => void
    routerQuery: ReturnType<typeof useSearchParams>
    routerPath: string
  }): TypedThunk =>
  (dispatch) => {
    dispatch(removeDataFromLocalStorage(IS_SIGN_UP_SESSION, false))
    dispatch(authSuccessHandler({ routerPush, routerQuery, routerPath }))
    trackLogin('Email')
  }

const authTrackLogin =
  ({ method }: { method: string }): TypedThunk =>
  (dispatch) => {
    dispatch(removeDataFromLocalStorage(IS_SIGN_UP_SESSION, false))
    trackLogin(method)
  }

const showAuthConfirmDialog =
  ({ socialType, token, email }: ShowAuthConfirmDialog): TypedThunk =>
  (dispatch) => {
    dispatch(
      openDialog({
        modalName: 'AUTH_CONFIRM_DIALOG',
        dialogProps: {
          socialType,
          token,
          email,
        },
      })
    )

    dispatch(removeDialogByName({ modalName: 'PRELOADER_DIALOG' }))
  }

const actionsMap: Record<string, ActionTypeMapFnAuth> = {
  [authOpenSnackbarActionName]: openSnackbar,
  [authOpenDialogActionName]: openDialog,
  [authSetOpenPrerequisitesActionName]: setOpenPrerequisites,
  [authSuccessHandleActionName]: authSuccessHandleAction,
  [authViaEmailSuccessActionName]: authViaEmailSuccessHandleAction,
  [authRemoveAuthTypeFromLocalStorageActionName]: authRemoveAuthTypeFromLocalStorage,
  [authSocialSignUpSuccessActionName]: authSocialSignUpSuccess,
  [authLogOutSuccessActionName]: logOutSuccess,
  [authSetAuthTypeFromLocalStorageActionName]: authSetAuthTypeFromLocalStorage,
  [authErrorHandleActionName]: authErrorHandler,
  [authLoginSuccessHandleActionName]: authLoginSuccessHandle,
  [authTrackLoginActionName]: authTrackLogin,
  [authShowAuthConfirmDialogActionName]: showAuthConfirmDialog,
  [authOpenErrorSnackbarActionName]: openErrorSnackbar,
}

export const authMiddleware: MiddlewareType =
  ({ dispatch }) =>
  (next) =>
  (action: AnyAction) => {
    if (action.type in actionsMap) {
      dispatch(actionsMap[action.type](action?.payload))
    }
    next(action)
  }
