import useIsInviteFriendsAvailable from 'hooks/useIsInviteFriendsAvailable'
// import { getItemFormLocalStorage } from 'utils/localStorage'
import { useAppSelector } from 'store/hooks'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import {
  getWeeklyRewardBonusesSelector,
  isInvitationBonusAvailableSelector,
  isManualBonusAvailableSelector,
  isDailyBonusAvailableWithCommonConditionsAndGCGeneratorSelector,
} from 'store/modules/bonusesFlow/selectors'
// import { getJackpotMyStashWinSelector } from 'store/modules/jackpots/selectors'
import { isOffersAvailableSelector } from 'store/modules/shop/selectors'
import { getFreeSpinsSelector } from 'store/modules/freeSpins/selectors'
import {
  isQuickStartAvailable,
  skipUpdateLegalsSCDisabledSelector,
  skipUpdateLegalsScEnabledSelector,
} from 'store/modules/user/selectors'
import { getItemFormLocalStorageSelector, IS_LOYALTY_PROGRAM_SHOWN } from 'utils/localStorage'
import type { SessionDialogsFlags } from './types'
import {
  isOptInNowAvailableSelector,
  isNewFreeContributionRewardAvailableSelector,
} from 'store/modules/jackpots/selectors'
import { isSignUpSessionSelector } from 'store/modules/dialog/selectors'
import { useCountryConfig } from 'app/context/country'

// Get all flags for session dialogs
export const useSessionDialogFlags = (): SessionDialogsFlags => {
  const isQuickStart = useAppSelector(isQuickStartAvailable)
  // const wasOpenedPlayerSafety = getItemFormLocalStorage('PLAYER_SAFETY_DIALOG')
  const isOffersAvailable = useAppSelector(isOffersAvailableSelector)
  // const myStashWin = useAppSelector(getJackpotMyStashWinSelector)
  const sweepstakeEnabled = useAppSelector(sweepstakeEnabledSelector)
  const skipUpdateLegalsScEnabled = useAppSelector(skipUpdateLegalsScEnabledSelector)
  const skipUpdateLegalsSCDisabled = useAppSelector(skipUpdateLegalsSCDisabledSelector)
  const isLegalsUpdatedAvailable = !(sweepstakeEnabled ? skipUpdateLegalsScEnabled : skipUpdateLegalsSCDisabled)
  const isRewardAvailable = useAppSelector(isManualBonusAvailableSelector)
  const freeSpins = useAppSelector(getFreeSpinsSelector)
  const isInviteFriendsAvailable = useIsInviteFriendsAvailable()
  const isInvitationRewardAvailable = useAppSelector(isInvitationBonusAvailableSelector)
  // open dialog if timer null
  // const timerId = getItemFormLocalStorage('WELCOME_EMAIL_DIALOG_TIMER_ID')

  const isWeeklyBonusAvailable = useAppSelector(getWeeklyRewardBonusesSelector)
  const { loyaltyProgramFeature } = useCountryConfig()
  const isLoyaltyProgramShown = useAppSelector((state) =>
    getItemFormLocalStorageSelector(state, IS_LOYALTY_PROGRAM_SHOWN, false)
  )
  const isSignUpSession = useAppSelector(isSignUpSessionSelector)
  const isOptInNowAvailable = useAppSelector(isOptInNowAvailableSelector)

  const isDailyBonusAvailableWithCommonConditionsAndGCGenerator = useAppSelector(
    isDailyBonusAvailableWithCommonConditionsAndGCGeneratorSelector
  )
  const isNewFreeContributionRewardAvailable = useAppSelector(isNewFreeContributionRewardAvailableSelector)

  return {
    isQuickStart,
    isPreviewSessionAvailable: false,
    isLegalsUpdatedAvailable,
    // isPlayerSafety: !wasOpenedPlayerSafety,
    isDailyBonusAvailable: isDailyBonusAvailableWithCommonConditionsAndGCGenerator,
    // isJackpotMyStashUnlocked: !!myStashWin.amount,
    isFreeSpinsAvailable: freeSpins?.length > 0,
    isRewardAvailable,
    isOffersAvailable,
    isInvitationRewardAvailable,
    // isWelcomeEmailAvailable: !timerId,
    isInviteFriendsAvailable: isInviteFriendsAvailable && !isSignUpSession,
    isWeeklyBonusAvailable: isWeeklyBonusAvailable?.length > 0,
    isOptInNowAvailable: isOptInNowAvailable && !isSignUpSession,
    isLoyaltyProgramDialogAvailable: loyaltyProgramFeature.enabled && !isLoyaltyProgramShown && !isSignUpSession,
    isNewFreeContributionRewardAvailable,
  }
}
