import { useCountryConfig } from 'app/context/country'
import { usePathname } from 'next/navigation'
import { useMemo } from 'react'
import { useAppSelector } from 'src/store/hooks'
import { getLocationDataSelector, isSCVisibleSelector } from 'store/modules/appConfig/selectors'
import {
  getUserFirstPurchaseSelector,
  getUserRegisteredCountrySelector,
  isLoggedInSelector,
} from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'
const ALLOWED_COUNTRY = 'US'
const RESTRICTED_ROUTES = [ROUTES.IDENTITY_VERIFICATION]

export const SNIPPET_KEY = process.env.ZENDESK_SNIPPET_KEY
export const APP_ID = process.env.ZENDESK_APP_ID
export const BLOCKED_STATES = process.env.ZENDESK_BLOCKED_STATES
export const CHAT_BOT_ID = process.env.CHAT_BOT_ID

export default function useShowChats(): {
  isShowLiveChat: boolean
  isShowChatBot: boolean
  isShowChatBotCom: boolean
} {
  const pathname = usePathname()
  const loggedIn = useAppSelector(isLoggedInSelector)
  const scVisible = useAppSelector(isSCVisibleSelector)
  const location = useAppSelector(getLocationDataSelector)
  const firstPurchase = useAppSelector(getUserFirstPurchaseSelector)
  const country = useAppSelector(getUserRegisteredCountrySelector)
  const { liveChatFeature } = useCountryConfig()

  const isGamePage = pathname?.includes('/play') && !pathname?.includes('/play-')
  const isLoginAndCountryAllowed = loggedIn && country === ALLOWED_COUNTRY
  const blockedStates = typeof BLOCKED_STATES === 'string' ? BLOCKED_STATES.split(',') : (BLOCKED_STATES as string[])

  const isRouteRestricted = useMemo((): boolean => RESTRICTED_ROUTES.includes(pathname), [pathname])

  if (!liveChatFeature.enabled) {
    return {
      isShowLiveChat: false,
      isShowChatBot: false,
      isShowChatBotCom: false,
    }
  }

  // [FYI]: [firstPurchase === undefined] need for waiting response from GetPaymentMetaInfoResponse
  if (isGamePage || !isLoginAndCountryAllowed || firstPurchase === undefined || isRouteRestricted) {
    return {
      isShowLiveChat: false,
      isShowChatBot: false,
      isShowChatBotCom: false,
    }
  }

  const isExistZendeskKeys = Boolean(APP_ID && SNIPPET_KEY)
  const isShowChatBots = !scVisible && !blockedStates.find((el) => el === location?.state)
  const isLiveChatEnabled = isLoginAndCountryAllowed && scVisible && !!firstPurchase
  const liveChatSC = isLiveChatEnabled && isExistZendeskKeys // [FYI]: general logic for Live chat

  return {
    isShowLiveChat: liveChatSC,
    isShowChatBot: isShowChatBots && isExistZendeskKeys,
    isShowChatBotCom: isShowChatBots && Boolean(CHAT_BOT_ID),
  }
}
