import { useEffect } from 'react'
import { useAppDispatch } from 'src/store/hooks'
import { setAuthLoading } from 'store/modules/auth/actions'

function TurnOffSpinnersInButton(): null {
  const dispatch = useAppDispatch()

  useEffect(() => {
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        dispatch(setAuthLoading({ loading: false }))
      }
    })
  }, [])

  return null
}

export default TurnOffSpinnersInButton
