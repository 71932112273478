'use client'

import { createContext, memo, useContext, useMemo } from 'react'
import getCountryConfig from 'app/utils/getCountryConfig'
import { useAppSelector } from 'store/hooks'
import { isLoggedInSelector } from 'store/modules/user/selectors'
import { getCookie } from '@patrianna/shared-utils/cookie'
import { USER_COUNTRY } from 'app/constants/cookies'
import type { Country } from 'app/utils/getCountryConfig'
import type { ReactNode } from 'react'

const CountryConfigContext = createContext<Country>(null)
CountryConfigContext.displayName = 'CountryConfigContext'

type Props = {
  children: ReactNode
  countryCode: string
}

function CountryConfigProvider(props: Props) {
  const { children, countryCode } = props
  const isLoggedIn = useAppSelector(isLoggedInSelector)

  const memoizedCountryInfo = useMemo(() => {
    const userCountryCode = isLoggedIn ? getCookie(USER_COUNTRY, document.cookie) : countryCode
    const countryConfig = getCountryConfig(userCountryCode)

    return {
      ...countryConfig,
      countryCode: userCountryCode,
    }
  }, [isLoggedIn, countryCode])

  return <CountryConfigContext.Provider value={memoizedCountryInfo}>{children}</CountryConfigContext.Provider>
}

const CountryConfigProviderMemo = memo(CountryConfigProvider)

function useCountryConfig() {
  const country = useContext(CountryConfigContext)

  if (country === undefined) {
    throw new Error('useCountry must be used within a CountryProvider')
  }

  return country
}

export { CountryConfigProviderMemo as CountryConfigProvider, useCountryConfig }
