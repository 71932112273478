import { usePathname } from 'next/navigation'
import { useEffect } from 'react'
import { strStartsWith } from '@patrianna/shared-utils/helpers'
import { useAppSelector } from 'src/store/hooks'
import { showOtpPageSelector } from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'

const allowedPages = [ROUTES.SWEEPSTAKE_RULES, ROUTES.TERMS, ROUTES.RESPONSIBLE_PLAY, ROUTES.PRIVACY, ROUTES.OTP]

export default function useOtpRedirect() {
  const pathname = usePathname()
  const showOtpPage = useAppSelector(showOtpPageSelector)

  const isAllowedPage = allowedPages.some((route) => strStartsWith(route, pathname))
  const isOtpRedirect = !isAllowedPage && showOtpPage

  useEffect(() => {
    if (isOtpRedirect) {
      window.location.href = ROUTES.OTP
    }
  }, [isOtpRedirect])
}
