import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { PhoneVerificationState } from 'store/types/PhoneVerificationModule'

export const initialState: PhoneVerificationState = {
  phoneCode: '',
  phoneNumber: null,
  timerRunning: false,
  channel: 'sms',
}

export const phoneVerificationSlice = createSlice({
  name: 'phoneVerification',
  initialState,
  reducers: {
    setTimerState: (state, action: PayloadAction<{ timerRunning: boolean }>) => {
      state.timerRunning = action.payload.timerRunning
    },

    setPhoneNumber: (state, action: PayloadAction<{ phoneNumber: string }>) => {
      state.phoneNumber = action.payload.phoneNumber
    },

    setChannel: (state, action: PayloadAction<{ channel: 'sms' | 'call' }>) => {
      state.channel = action.payload.channel
    },

    clearPhoneVerification: () => initialState,
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof phoneVerificationSlice.actions>>

export const { actions } = phoneVerificationSlice
