'use client'

import { useEffect } from 'react'

/**
 * This component is a workaround, which is aimed to fix CSS Transition flash on page load
 * in a NextJS production build by disabling transitions during page load.
 * Ideally, it should be removed in a future
 * when problem is resolved in next versions of NextJS
 * */
export function CssTransitionsPreloader() {
  useEffect(() => {
    const node = document.querySelector('.transition-preload')

    node?.classList.remove('transition-preload')
  }, [])

  return (
    <style>
      {`
      .transition-preload * {
        transition: none !important;
      }
    `}
    </style>
  )
}
