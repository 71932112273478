import { clarityIdentify } from '@patrianna-payments/analytics/clarity/events'
import { graphyteIdentify } from '@patrianna-payments/analytics/graphyte/events'
import { configureScope } from '@sentry/nextjs'

import { purchaseResponseListenerByRouter } from '@patrianna-payments/shared-store/payments/store/middleware-actions'
import { getPendingRedeemCountRequest } from '@patrianna-payments/shared-store/redeem/store/actions'
import { getSkipOtp } from '@patrianna-payments/shared-store/skip-otp'
import { identify, trackEvent } from 'config/analytic'
import type { useSearchParams } from 'next/navigation'
import getAccountBonusesRequest from 'src/services/gateway/requests/getAccountBonuses'
import type { TypedThunk } from 'src/store/types'
import { getAccountInfo } from 'src/utils/getAccountInfo'
import { fetchAllPromotions, fetchVisitedPromotions } from 'store/modules/promotions'
import { getOffers } from 'store/modules/shop/actions'
import { setIsLoadingInfo } from 'store/modules/user/actions'
import {
  getLiveChatSettingsRequest,
  getUserPaymentMetaData,
  setUser,
  setUserCountry,
  setXPLevel,
} from 'store/modules/user/actions'
import { enabledSweepstake, initApp, saveLocationData } from '../appConfig/actions'
import { detectExpiredSession, runsCurrentAuthFlow } from '../auth/actions'
import { defaultCurrencyHandler, initCurrencies } from '../currencies/actions'
import { openDialog } from '../dialog/actions'
import { getAccountJackpotInfo } from '../jackpots/actions'
import { setOpenPrerequisites } from '../prerequisites/actions'
import { waitingForSessionDialogsData } from '../sessionDialogs/actions'
import { getUserFreeSpins } from '../freeSpins/actions'
import { getPragmaticJackpotsAction } from '../pragmaticJackpots/actions'
import { LOGGED_IN, USER_COUNTRY } from 'app/constants/cookies'
import { clearAuthCookies } from 'src/utils/clearAuthCookies'
import { setCookie } from '@patrianna/shared-utils/cookie'
import { requestGoldCoinsGenerator, setGcGeneratorEnabled } from 'store/modules/gcGenerator/actions'

const isJackpotEnabled = process.env.JACKPOT_ENABLED

export const userInitilization =
  (
    routerPush: (path: string, callback?: () => void) => void,
    routerQuery: ReturnType<typeof useSearchParams>,
    routerPath: string,
    initializedCallback?: ({ country, userId, state }: { country: string; userId: string; state?: string }) => void
  ): TypedThunk =>
  (dispatch, _, { gateway }) => {
    dispatch(setIsLoadingInfo({ loading: true }))
    getAccountInfo(gateway)
      .then((body) => {
        const { balances, prerequisites, location, ...user } = body

        setCookie(LOGGED_IN, '1', { path: '/', expires: new Date(Date.now() + 31536000000) })
        setCookie(USER_COUNTRY, user.country, { path: '/', expires: new Date(Date.now() + 31536000000) })

        // do not wrap with if statement, because we need to load FreeSpins anyway
        dispatch(enabledSweepstake(user.sweepstake))
        dispatch(defaultCurrencyHandler(user.mode, user.sweepstake, routerPath))
        dispatch(getUserFreeSpins(user.sweepstake))
        setUserCountry({ country: location?.country })
        dispatch(setGcGeneratorEnabled({ enabled: user.freeGoldCoins }))

        // save user id in localstorage for personalized settings
        configureScope((scope) => scope.setUser({ id: user.id, email: user.email }))

        // send ga event
        trackEvent('user_data', {
          category: 'user_info',
          label: 'user_info',
          fn: user.firstName,
          ln: user.lastName,
          em: user.email,
          ph: user.phoneNumber,
          db: user.birthDate?.replace(/-/g, ''),
          zp: user.kycInfo?.zip || user.softKycInfo?.zip,
          ct: user.kycInfo?.city || user.softKycInfo?.city,
          external_id: user.id.split('/')[0],
        })

        // tikTokIdentify(user.email, user.phoneNumber)
        identify(user.id, { bloomreach: [{ email: user.email, UUID: user.id }] })
        graphyteIdentify(user.id)
        clarityIdentify(user.id)

        if (initializedCallback) {
          initializedCallback({
            country: body.country,
            userId: user.id,
            ...(body.location.state && { state: body.location.state }),
          })
        }

        if (user.kycStatus === 'blocked' || user.locked) {
          dispatch(openDialog({ modalName: 'LOCK_USER_DIALOG', dialogProps: { singletone: true } }))
        }

        dispatch(setUser({ data: user }))

        dispatch(purchaseResponseListenerByRouter())
        dispatch(saveLocationData({ location }))
        dispatch(setXPLevel({ xpLevel: user.xpLevel }))
        // dispatch(getAccountInfo())
        dispatch(getSkipOtp())
        dispatch(requestGoldCoinsGenerator())
        dispatch(getOffers(true))
        dispatch(fetchAllPromotions(user.sweepstake))
        dispatch(fetchVisitedPromotions([]))
        dispatch(getLiveChatSettingsRequest())

        if (balances) {
          dispatch(initCurrencies({ currencies: balances }))
        }

        // dispatch(saveBuildInfo({ lottoBuildInfo: body.lottoBuildInfo }))

        if (user.status !== 'RESTRICTED_BY_GEO_POLICY') {
          dispatch(getAccountBonusesRequest())
        } else {
          dispatch(waitingForSessionDialogsData({ dailyBonuses: true }))
        }

        dispatch(getAccountJackpotInfo())

        if (isJackpotEnabled) {
          dispatch(getPragmaticJackpotsAction(user.sweepstake ? 'SC' : 'GC'))
        }

        dispatch(setOpenPrerequisites({ prerequisites }))
        dispatch(getUserPaymentMetaData())
        dispatch(getPendingRedeemCountRequest())

        // action handles autologin or opens auth ways dialog
        dispatch(runsCurrentAuthFlow(Boolean(user), routerQuery, routerPush, routerPath))
        dispatch(detectExpiredSession(Boolean(user)))
      })
      .catch(({ body }) => {
        const { location, sweepstake } = body || {}

        clearAuthCookies()

        dispatch(getOffers())
        dispatch(enabledSweepstake(sweepstake))
        dispatch(waitingForSessionDialogsData({ dailyBonuses: true }))
        dispatch(setUserCountry({ country: location?.country }))
        dispatch(getPragmaticJackpotsAction(sweepstake ? 'SC' : 'GC'))

        if (isJackpotEnabled) {
          dispatch(getPragmaticJackpotsAction(sweepstake ? 'SC' : 'GC'))
        }
        console.log('err', 21)

        // action handles autologin or opens auth ways dialog
        dispatch(runsCurrentAuthFlow(Boolean(false), routerQuery, routerPush, routerPath))
        dispatch(detectExpiredSession(Boolean(false)))
      })
      .finally(() => {
        dispatch(waitingForSessionDialogsData({ legalUpdate: true }))
        dispatch(initApp())
        dispatch(setIsLoadingInfo({ loading: false }))
      })
  }
