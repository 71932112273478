import type { AnyAction, CombinedState, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { configureStore } from '@reduxjs/toolkit'
import { verificationsMiddleware } from 'store/middlewares/verificationsMiddleware'
import { paymentsMiddleware } from 'store/middlewares/paymentsMiddleware'
import { redeemMiddleware } from 'store/middlewares/redeemMiddleware'
import { authMiddleware } from './middlewares/authMiddleware'
import rootReducer from './modules/rootReducer'
import type { ServiceDependenciesOptions } from 'src/services'
import { serviceDependencies } from 'src/services'

const defaultReduxMiddlewareOptions = {
  thunk: {
    extraArgument: serviceDependencies,
  },
}

const makeStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      /*
        With this configuration, we can use "serviceDependencies" in each thunk and access to our API via third argument

        Example:
        const getAccountInfo = () => (dispatch, getState, { gateway }) => {
          gateway.emit<GetAccountInfoRequest>({ type: 'GetAccountInfoRequest' })
        }

        Docs: https://redux-toolkit.js.org/api/getDefaultMiddleware
      */
      getDefaultMiddleware(defaultReduxMiddlewareOptions).concat([
        verificationsMiddleware,
        redeemMiddleware,
        authMiddleware,
        paymentsMiddleware,
      ]),
    devTools: process.env.PROFILE === 'dev',
  })
}

const store = makeStore()

type CleanState<T> = T extends CombinedState<infer S> ? { [K in keyof S]: CleanState<S[K]> } : T

type AppDispatch = typeof store.dispatch
type ReduxState = CleanState<ReturnType<typeof rootReducer>>
type TypedDispatch = ThunkDispatch<ReduxState, any, AnyAction>
type TypedThunk<ReturnType = void> = ThunkAction<ReturnType, ReduxState, ServiceDependenciesOptions, AnyAction>

export type { AppDispatch, ReduxState, TypedDispatch, TypedThunk, ServiceDependenciesOptions }

export default store
