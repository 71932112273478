import type { GetAccountSettingsRequest } from '@patrianna/shared-patrianna-types/websocket/requests'
import type { GetAccountSettingsResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import { setRedeemPolicy } from '@patrianna-payments/shared-store/redeem'
import type { TypedThunk } from 'src/store/types'
import { savePasswordPolicy } from 'store/modules/appConfig/actions'

const getAccountSettingsRequest =
  (): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const data: GetAccountSettingsRequest = {
      type: 'GetAccountSettingsRequest',
    }

    gateway
      .emit<GetAccountSettingsResponse>(data)
      .then((body) => {
        dispatch(
          setRedeemPolicy({
            policy: {
              sweepstakeRedeemPolicy: body.sweepstakeRedeemPolicy,
              fiatRedeemPolicy: body.fiatRedeemPolicy,
              nonMonetaryRedeemPolicy: body.nonMonetaryRedeemPolicy,
            },
          })
        )
        dispatch(savePasswordPolicy({ passwordPolicy: body.passwordPolicy }))
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
  }

export default getAccountSettingsRequest
