import type { Currencies } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'
import type { GameProduct } from '@patrianna/shared-patrianna-types/store/GameModule'
import type { LowOnCoinsRequest } from '@patrianna/shared-patrianna-types/websocket/requests'
import type { LowOnCoinsResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import { skipOtpSelector } from '@patrianna-payments/shared-store/skip-otp/store/selectors'
import type { TypedThunk } from 'src/store/types'
import { getGameByCode } from 'src/utils/loadGameInfoGetInitialProps'
import { closeAllDialogs, closeLatestDialog, openDialog, replaceDialog } from 'store/modules/dialog/actions'
import { getMaxPriorityOrRandomModalOfferSelector, isDiscountOffersAvailable } from 'store/modules/shop/selectors'
import { getUserRestrictionsSelector, isGuestModeSelector, isLoggedInSelector } from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'
import { setActiveCurrency } from '../currencies/actions'
import { actions } from './slice'
import type { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import { stringify } from 'querystring'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'

export const { setActiveGameCode, clearSlotGameFlow, setGoldMinBet, setSweepstakeMinBet, setLowOnCoinsLastDateOpened } =
  actions

export const goToPlayGame =
  (
    game: GameProduct,
    activeCurrency: Currencies,
    routerPush: (path: string, callback?: () => void) => void,
    options?: { [key: string]: string | number }
  ): TypedThunk =>
  (dispatch) => {
    const smBreakpoint = 927
    const isMobile = window.innerWidth < smBreakpoint

    const isCasinoGame = game?.tags?.includes('table')
    const rootUrl = isCasinoGame ? '/games' : ROUTES.GAMES_SLOTS

    dispatch(closeAllDialogs())

    const url = `${rootUrl}/${game.route}/${activeCurrency === 'GC' ? '' : 'sweepstake/'}play/${
      isMobile ? 'mobile' : ''
    }`

    routerPush(`${url}${options ? `?${stringify(options)}` : ''}`, () => {
      dispatch(setActiveCurrency({ activeCurrencyId: activeCurrency }))
    })
  }

export const startExternalGameFlow =
  (
    game: GameProduct,
    activeCurrency: Currencies,
    routerPush: (path: string, callback?: () => void) => void,
    options?: { [key: string]: string | number }
  ): TypedThunk =>
  (dispatch, getState) => {
    const isGuest = isGuestModeSelector(getState())
    const isLoggedIn = isLoggedInSelector(getState())
    const skipOtp = skipOtpSelector(getState())
    const isOTPrequired = isLoggedIn && !skipOtp && activeCurrency === 'SC'
    const userRestrictions = getUserRestrictionsSelector(getState())

    if (!isLoggedIn) {
      dispatch(closeAllDialogs())
      routerPush(ROUTES.LOGIN)
    } else if (isGuest) {
      dispatch(openDialog({ modalName: 'SCPR_AUTH_WAYS_DIALOG' }))
    } else if (isOTPrequired) {
      dispatch(closeAllDialogs())
      dispatch(openDialog({ modalName: 'PHONE_VERIFICATION_DIALOG' }))
    } else if (userRestrictions.includes('no_game')) {
      dispatch(closeAllDialogs())
      dispatch(openDialog({ modalName: 'RESTRICT_USER_DIALOG' }))
    } else {
      dispatch(goToPlayGame(game, activeCurrency, routerPush, options))
    }
  }

export const showLowOnCoinsDialog =
  (currencyName: string): TypedThunk =>
  (dispatch, getState) => {
    const isOfferAvaialble = isDiscountOffersAvailable(getState())
    const activeCurrency = currencyName ? 'SC' : 'GC'
    const modalName = activeCurrency === 'SC' ? 'NOT_ENOUGH_FUNDS' : 'LOW_COINS_DIALOG'
    const sweepstakeEnabled = sweepstakeEnabledSelector(getState())
    const offer = getMaxPriorityOrRandomModalOfferSelector(getState())

    dispatch(
      replaceDialog({
        modalName,
        ...(sweepstakeEnabled && {
          dialogProps: {
            triggeredOnLowOnCoins: true,
          },
        }),
      })
    )
    dispatch(setLowOnCoinsLastDateOpened({ lowOnCoinsLastDateOpened: Date.now() }))

    if (isOfferAvaialble && offer) {
      dispatch(replaceDialog({ modalName: 'EXTRA_GOLD_DIALOG', dialogProps: { triggeredOnLowOnCoins: true } }))
    }

    return null
  }

export const cleanUpGame = (): TypedThunk => (dispatch) => {
  dispatch(clearSlotGameFlow())
}

export const playInfiniteGames =
  (query: NextParsedUrlQuery, routerPush: (path: string, callback?: () => void) => void) =>
  (): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const activeCurrency = query?.currencyName ? 'SC' : 'GC'
    const data: LowOnCoinsRequest = {
      type: 'LowOnCoinsRequest',
    }

    gateway
      .emit<LowOnCoinsResponse>(data)
      .then((body) => {
        if (body.route) {
          getGameByCode(body.route).then((_game) => {
            dispatch(setActiveGameCode({ code: _game.code }))
            dispatch(setGoldMinBet({ goldMinBet: _game.goldMinBet }))
            dispatch(setSweepstakeMinBet({ sweepstakeMinBet: _game.sweepstakeMinBet }))
            dispatch(goToPlayGame(_game, activeCurrency, routerPush))
          })
          closeLatestDialog()
        }
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
  }
