import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { PragmaticJackpot, PragmaticJackpotState } from './types'

const initialState: PragmaticJackpotState = {
  jackpots: [],
}

export const pragmaticJackpotSlice = createSlice({
  name: 'pragmaticJackpot',
  initialState,
  reducers: {
    savePragmaticJackpots: (state, action: PayloadAction<{ jackpots: Array<PragmaticJackpot> }>) => {
      state.jackpots = action.payload.jackpots
    },
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof pragmaticJackpotSlice.actions>>
export const { actions } = pragmaticJackpotSlice
