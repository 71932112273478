import { useChatBot } from '@patrianna-payments/chatbot'
import type { ZendeskChatBotProps } from '@patrianna-payments/zendesk'
import ZendeskChatBot from '@patrianna-payments/zendesk'
import { useAppSelector } from 'src/store/hooks'
import {
  getLiveChatDepartmentsSelector,
  getLiveChatSettingsSelector,
  getUserSelector,
} from 'store/modules/user/selectors'
import useShowChats, { APP_ID, CHAT_BOT_ID, SNIPPET_KEY } from 'hooks/useShowChats'
import useDomEvent from 'hooks/useDomEvent'

export default function Chats() {
  const user = useAppSelector(getUserSelector)
  const departments = useAppSelector(getLiveChatDepartmentsSelector)
  const liveChatSettings = useAppSelector(getLiveChatSettingsSelector)

  const { isShowLiveChat, isShowChatBot, isShowChatBotCom } = useShowChats()

  useChatBot(CHAT_BOT_ID, !isShowLiveChat && isShowChatBotCom)

  const bottomBarStateHandler = (e: CustomEvent) => {
    const botContainer = document.getElementById('chatbot-chat')
    if (botContainer?.style) {
      botContainer.style.transform = `translateY(${e.detail.isBottomOpened ? '-135px' : '0'})`
    }
  }

  useDomEvent('BOTTOM_BAR_STATE', bottomBarStateHandler)

  if (!isShowLiveChat && !isShowChatBot && !isShowChatBotCom) {
    return null
  }

  const chatBotProps: ZendeskChatBotProps = {
    appId: APP_ID,
    snippetKey: SNIPPET_KEY,
    info: user,
  }

  if (isShowLiveChat) {
    return (
      <ZendeskChatBot
        {...chatBotProps}
        departments={departments}
        customerTag={liveChatSettings?.tag}
        additionalStyles='#launcher { display: none !important; }' // [FYI] live chat only can open from the menu
      />
    )
  }

  if (isShowChatBotCom) {
    return null
  }

  if (isShowChatBot) {
    return <ZendeskChatBot {...chatBotProps} withBot standaloneChatBot />
  }

  return null
}
