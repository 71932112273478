import { combineReducers } from '@reduxjs/toolkit'
import { authSlice } from '@patrianna/shared-store/auth/store/slice'
import { verificationsSlice } from '@patrianna-payments/shared-store/verifications/store/slice'
import { paymentsSlice } from '@patrianna-payments/shared-store/payments/store/slice'
import { redeemSlice } from '@patrianna-payments/shared-store/redeem/store/slice'
import { skipOtpSlice } from '@patrianna-payments/shared-store/skip-otp/store/slice'
import { categoryGamesSlice } from 'store/modules/categoryGames/slice'
import { accountInvitationsSlice } from './accountInvitations/slice'
import { appConfigSlice } from './appConfig/slice'
import { bonusesFlowSlice } from './bonusesFlow/slice'
import { currenciesSlice } from './currencies/slice'
import { dialogSlice } from './dialog/slice'
import { gamesSlice } from './games/slice'
import { jackpotsSlice } from './jackpots/slice'
import { phoneVerificationSlice } from './phoneVerification/slice'
import { playedGamesSlice } from './playedGames/slice'
import { pragmaticJackpotSlice } from './pragmaticJackpots/slice'
import { prerequisitesSlice } from './prerequisites/slice'
import { promotionsSlice } from './promotions/slice'
import { sessionDialogsSlice } from './sessionDialogs/slice'
import { shopSlice } from './shop/slice'
import { slotGameFlowSlice } from './slotGameFlow/slice'
import { snackbarSlice } from './snackbar/slice'
import { userSlice } from './user/slice'
import { gcGeneratorSlice } from './gcGenerator/slice'
import { freeSpinsSlice } from 'store/modules/freeSpins/slice'

const rootReducer = combineReducers({
  appConfig: appConfigSlice.reducer,
  snackbar: snackbarSlice.reducer,
  currencies: currenciesSlice.reducer,
  user: userSlice.reducer,
  games: gamesSlice.reducer,
  playedGames: playedGamesSlice.reducer,
  dialog: dialogSlice.reducer,
  prerequisites: prerequisitesSlice.reducer,
  promotions: promotionsSlice.reducer,
  shop: shopSlice.reducer,
  slotGameFlow: slotGameFlowSlice.reducer,
  bonusesFlow: bonusesFlowSlice.reducer,
  phoneVerification: phoneVerificationSlice.reducer,
  jackpots: jackpotsSlice.reducer,
  sessionDialogs: sessionDialogsSlice.reducer,
  [verificationsSlice.name]: verificationsSlice.reducer,
  [skipOtpSlice.name]: skipOtpSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  accountInvitations: accountInvitationsSlice.reducer,
  [redeemSlice.name]: redeemSlice.reducer,
  [paymentsSlice.name]: paymentsSlice.reducer,
  pragmaticJackpot: pragmaticJackpotSlice.reducer,
  [categoryGamesSlice.name]: categoryGamesSlice.reducer,
  [freeSpinsSlice.name]: freeSpinsSlice.reducer,
  gcGenerator: gcGeneratorSlice.reducer,
})

export default rootReducer
